<template>
  <v-card flat tile>
    <v-overlay :value="overlay" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-toolbar flat color="white">
      <v-toolbar-title>
        <v-icon class="mb-1">mdi-account</v-icon>
        CONTACTOS
      </v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <!-- <v-autocomplete
        v-model="contacto_id"
        small
        item-text="DESCRIPCION"
        item-value="ID"
        :items="listaAutocomplete"
        @change="cargarLista()"
        clearable
        label="Filtro autocomplete"
        class="mt-5"
      >
      </v-autocomplete> -->
      <v-text-field
        class="px-4 mt-2"
        label="Contacto"
        v-model="busContacto"
        append-icon="mdi-magnify"
        @keyup.enter="cargarDatos()"
      ></v-text-field>
      <v-text-field
        class="px-4 mt-2"
        label="Empresa"
        v-model="busEmpresa"
        append-icon="mdi-magnify"
        @keyup.enter="cargarDatos()"
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-toolbar flat color="white">
      <v-btn small color="info" class="mx-2"
        ><v-icon left>mdi-plus</v-icon>Crear</v-btn
      >
    </v-toolbar>

    <v-data-table
      :headers="header"
      :options.sync="options"
      :items="lista"
      :itemsPerPage="itemsPerPage"
      :server-items-length="totalItems"
      class="elevation-1"
      show-select
      :single-select="true"
      v-model="seleccion"
      item-key="CONTCATO_ID"
      hide-default-footer
    >
      <template v-slot:item.NOMBRE="{ item }">
        <v-btn small text :to="`/form_contacto/${item.CONTACTO_ID}`">
          <v-icon left small>mdi-eye</v-icon>{{ item.NOMBRE }}</v-btn
        >
      </template>
      <template v-slot:footer>
        <v-pagination
          class="mt-10"
          v-model="currentPage"
          :length="pageCount"
          @input="handlePageChange"
          total-visible="10"
        ></v-pagination>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "Contactos",
  components: {},

  props: {},
  data: () => ({
    header: [
      { text: "Opciones", value: "opciones" },
      { text: "Código", value: "CONTACTO_ID" },
      { text: "Nombres", value: "NOMBRE" },
      { text: "Empresa", value: "EMPRESA_NOMBRE" },
      { text: "Email", value: "CORREO1" },
      { text: "Teléfono", value: "TELEFONO1" },
    ],
    options: {},
    overlay: false,

    lista: [],
    listaAutocomplete: [],
    seleccion: [],
    itemsPerPage: 50,
    totalItems: 0,
    currentPage: 1,
    pageCount: 0,
    busContacto: "",
    busEmpresa: "",
  }),
  computed: {
    ...mapState("master", ["loadingBtn"]),
  },
  methods: {
    ...mapActions("master", ["requestApi", "alertNotification"]),
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setTitleToolbar",
      "setLoadingTable",
    ]),
    ...mapActions("master", ["requestApi", "alertNotification"]),
    cargarDatos() {
      this.setLoadingTable(true);
      this.setUrl("api/contactos");
      this.requestApi({
        method: "GET",
        data: {
          busContacto: this.busContacto,
          busEmpresa: this.busEmpresa,
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
        },
      })
        .then((res) => {
          this.lista = res.data.lista;
          this.totalItems = res.data.totalItems;
          this.pageCount = res.data.pageCount;
          //this.coordAbiertas = res.data.hcoord_activa;
          this.setLoadingTable(false);
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },
    cargarLista() {},
    handlePageChange() {
      this.cargarDatos();
    },
  },
  mounted() {
    this.cargarDatos();
  },
};
</script>
